const Boxes = {
  box1: function () {
	  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-1, 21, 21, -1],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});

brd1.suspendUpdate();

brd1.options.layer['image'] =14;

//Title and subtitle
var title1 = brd1.create('text', [8, 19, '<b> Polynomials: Coefficient and Order <b>'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);

var txt2 = brd1.create('text', [7, 10, 'What is the coefficient of x ?  '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
var txt3 = brd1.create('text', [7, 7, 'What is the order of the polynomial ?  '],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

//Variables
var a = 1;
var b = -1;
var c = 7;
var o = 2;
var txt_b = '- 1';
var txt_c = '+ 2';

//Images
var shuffle = brd1.create('image', ['/assets/shuffle.svg', [8, 3.5], [2 , 2]], {visible: true, fixed: true});
var tryMe = brd1.create('image', ['/assets/test.svg', [15, 3.5], [2 , 2]], {visible: true, fixed: true});

var ok1 = brd1.create('image', ['/assets/check.svg', [18, 12.5], [1.5 ,1.5]], {visible: false, fixed: true});
var ok2 = brd1.create('image', ['/assets/check.svg', [18, 9.5], [1.5 ,1.5]], {visible: false, fixed: true});
var ok3 = brd1.create('image', ['/assets/check.svg', [18, 6.5], [1.5 ,1.5]], {visible: false, fixed: true});

var cross1 = brd1.create('image', ['/assets/cross.svg', [18, 12.5], [1.5 ,1.5]], {visible: false, fixed: true});
var cross2 = brd1.create('image', ['/assets/cross.svg', [18, 9.5], [1.5 ,1.5]], {visible: false, fixed: true});
var cross3 = brd1.create('image', ['/assets/cross.svg', [18, 6.5], [1.5 ,1.5]], {visible: false, fixed: true});

//Shuffle function
shuffle.on('down',function(){

    ok1.setAttribute({visible: false});
    cross1.setAttribute({visible: false});
    ok2.setAttribute({visible: false});
    cross2.setAttribute({visible: false});
    ok3.setAttribute({visible: false});
    cross3.setAttribute({visible: false});

    a=0;

    while(a == 0){
        a = Math.round(Math.random()*100-50);
    }

    b=0;
    while(b == 0){
        b = Math.round(Math.random()*100 -50);

        if(b>= 0){
            txt_b = '+ ' + b;
        }
        else{
            txt_b = b;
        }
    }

    c=0;
    while(c == 0){
        c = Math.round(Math.random()*100 -50);

        if(c>= 0){
            txt_c = '+ ' + c;
        }
        else{
            txt_c = c;
        }

    }

    o=0;
    while(o < 2){
        o = Math.round(Math.random()*9);

    }

    //coefficient1.Value() = 'ppp'; // I want to reset the input values
});


shuffle.setLabel('Shuffle')
shuffle.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
shuffle.on('over', function () {this.label.setAttribute({visible:true});});
shuffle.on('out', function () {this.label.setAttribute({visible:false});});

tryMe.setLabel('Try')
tryMe.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
tryMe.on('over', function () {this.label.setAttribute({visible:true});});
tryMe.on('out', function () {this.label.setAttribute({visible:false});});


var polynomial1 = brd1.create('text', [10, 16, function(){return 'f(x) = '+ a + ' x^' + o  + ' ' +txt_b + ' x ' + txt_c }],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/500.)}},);

var txt1 = brd1.create('text', [7, 13, function(){ return 'What is the coefficient of x^' + o  +  '? ' }],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

var coefficient1 = brd1.create('input', [15, 13, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:10%; background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true,parse:true});
var coefficient2 = brd1.create('input', [15, 10, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:10%; background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true,parse:true});
var order = brd1.create('input', [15, 7, '', ''], {fixed: true, cssStyle: 'fontFamily:Oswald; width:10%; background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true,parse:true});


function check(){

    if(coefficient1.Value() == a){

        ok1.setAttribute({visible: true});
        cross1.setAttribute({visible: false});

    }

    else{
        ok1.setAttribute({visible: false});
        cross1.setAttribute({visible: true});
    }


    if(coefficient2.Value() == b){

        ok2.setAttribute({visible: true});
        cross2.setAttribute({visible: false});

    }

    else{
        ok2.setAttribute({visible: false});
        cross2.setAttribute({visible: true});
    }

    if(order.Value() == o){

        ok3.setAttribute({visible: true});
        cross3.setAttribute({visible: false});

    }

    else{
        ok3.setAttribute({visible: false});
        cross3.setAttribute({visible: true});
    }

    coefficient1.setAttribute({display: '0'});
}


tryMe.on('down', check);

//brd1.create('text', [15.5 , 4 , '<button onclick="check()">Try!</button>'] , {fixed: true});


brd1.unsuspendUpdate();
}
}
export default Boxes;
