<template>
  <div>
    <h3 ref="intro">
      Polynomials
    </h3>
    <p>
      The mean value theorem, stated simply, says that for a continuous and differentiable function between two end points, there exists at least one point in that interval at which
      the local rate of change of the function (given by the derivative at that point) is same as the average rate of change of the function over that interval (given by the slope of the line joining the two end points).
    </p>
    <h3 ref="formal">
      Order of a Polynomial
    </h3>
    <p>
      Consider a function \( f:[a,b]\to \mathbb {R} \) that is continuous on the closed interval \([a, b]\), and is differentiable on the open interval \((a, b)\).
      Then there exists at least one point in that interval \( c \in (a,b)\) such that
      $$ f'(c)=\frac{f(b)-f(a)}{b-a}$$
      where \(f'(c)\) denotes the derivative of the function \(f\) at \(c\).
    </p>
    <h3 ref="formal">
      Coefficients
    </h3>
    <p />
    <h3 ref="playgraph">
      MagicGraph | Order & Coefficients of Polynomials
    </h3>
    <p>
      The purpose of the interactive illustration below is to help students understand the mean value theorem. Shown by a solid black curve is a function  \(f \) that is continuous
      and differentiable in the range \((a, b)\).
      This function can be interactively changed by moving the four anchor points &mdash; A, B, C, and D (shown by empty circle symbols).
      The blue dashed line is the line connecting the two end points A and B. Point P (denoted by an empty square symbol) can glide on this curve.
      The red line is the tangent to the curve going through point P. Mean value theorem states that there is at least one location of point P on this curve where
      the slope of the tangent (red dashed line) is same as that of the connecting line AB (blue dashed line).
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ForcesStresses',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Polynomials: Order & Coefficients';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Introduction', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Statement of Theorem', img:'/assets/number-2.svg', action: () => this.goto('formal')},
      {title: 'MagicGraph', img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
